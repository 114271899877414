<template>
  <div>
    <PageHeader />

    <b-row class="mb-3">
      <b-col v-if="isOwner" cols="12" md="4">
        <MasterSelectInput 
          :value="masterId"
          hide-label
          has-select-all
          @update="onMasterIdChange"
        />
      </b-col>
      <b-col v-if="isOwner || isMaster" cols="12" md="4">
        <AgentsSelectInput
          :value="agentId"
          :master-id="masterId"
          :disabled="!isAgent && !isMaster && !masterId"
          hide-label
          has-select-all
          is-show
          @update="onAgentIdChange"
        />
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col>
        <day-picker
          :initial-value="startDate"
          @update="(e) => (startDate = e)"
        ></day-picker>
      </b-col>
      <b-col>
        <day-picker
          :initial-value="endDate"
          @update="(e) => (endDate = e)"
        ></day-picker>
      </b-col>
      <b-col cols="2" class="text-right">
        <b-button block @click="fetchData">
          ดึงยอด
        </b-button>
      </b-col>
    </b-row>
    <b-overlay :show="isFetching">
      <b-card>
        <b-table
          :items="dataList"
          :fields="fields"
          responsive
          show-empty
          foot-clone
        >
          <template #cell(#)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(winAmount)="data">
            {{ data.value | currency }}
          </template>
          <template #cell(betAmount)="data">
            {{ data.value | currency }}
          </template>
          <template #cell(diff)="data">
            {{ data.value | currency }}
          </template>
          <template #cell(share)="data">
            {{ data.value | currency }}
          </template>
          <template #cell(total)="data">
            {{ data.value | currency }}
          </template>
          <template #foot(#)="">
            <span class="text-danger">รวม</span>
          </template>
          <template #foot()="data">
            <span class="text-danger">
              {{ displaySummary(data) }}
            </span>
          </template>
          <template #empty="">
            <p class="text-center text-muted">{{
                $t('messages.nothing_here')
              }}</p>
          </template>
        </b-table>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import dayjs from 'dayjs'

export default {
  page: {
    title: 'สรุปยอดเกม',
  },
  components: {
    AgentsSelectInput: () =>
      import('@components/agents/agents-select-input'),
    MasterSelectInput: () =>
      import('@components/master/master-select-input'),
  },
  data() {
    return {
      startDate: dayjs().subtract(1, 'd').format('YYYY-MM-DD'),
      endDate: dayjs().format('YYYY-MM-DD'),
      agentId: '',
      masterId: '',
      fields: [
        '#',
        {
          key: 'gameName',
          label: 'เกม',
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'betAmount',
          label: 'ยอดแทง',
          class: 'text-right',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'winAmount',
          label: 'ยอดถูก',
          class: 'text-right',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'diff',
          label: 'ส่วนต่าง',
          class: 'text-right text-info',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'share',
          label: 'ค่าส่วนแบ่งผู้ให้บริการ',
          class: 'text-right text-warning',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'total',
          label: 'รายได้สุทธิ',
          class: 'text-right text-success',
          thStyle: {
            minWidth: '150px',
          },
        },
      ],
    }
  },
  computed: {
    ...mapState({
      isFetching: (state) => state.user.isFetchingSummary,
      gameSummary: (state) => state.game.gameSummary,
    }),
    ...mapGetters(['isMaster', 'isOwner', 'isAgent', 'summary']),
    dataList() {
      return this.summary.map((prop) => {
        const {betAmount = 0, winAmount = 0, commissionRate} = prop;
        const diff = +betAmount - +winAmount;
        const share = diff * (100 - +commissionRate) / 100;
        const total = diff - share;
        return {
          ...prop,
          diff,
          share,
          total,
        }
      })
    },
  },
  methods: {
    ...mapActions(['fetchSummary']),
    fetchData() {
      this.fetchSummary({
        search: '',
        masterId: this.masterId, 
        agentId: this.agentId, 
        from: this.startDate,
        to: this.endDate,
      })
    },
    onAgentIdChange(agentId) {
      this.agentId = agentId
    },
    onMasterIdChange(masterId) {
      this.masterId = masterId
    },
    displaySummary(data) {
      switch (data.column) {
        case 'betAmount':
        case 'winAmount':
        case 'diff':
        case 'share':
        case 'total':
          // eslint-disable-next-line no-case-declarations
          const sum = this.dataList.length > 0? this.dataList.reduce((acc, item) => acc + +item[data.column], 0): 0
          return sum.toLocaleString('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })
        
        default:
          return ''
      }
    },
  },
}
</script>
